import React, { useState } from 'react';
import LinkNext from 'next/link';

import PropTypes from "prop-types";
import { Line } from 'atoms/skeleton/SkeletonLoader';

const LinkAnchor = React.forwardRef(({ className = '', onClick= ()=>{}, children = 'Link text', href = '#', as, dataAutomation, displayType = '', enableLoader = false, ...props }, ref) => {
    console.log("🚀 ~ LinkAnchor ~ onClick:", onClick)
    const [isLoading, setIsLoading] = useState(false);
    return (!isLoading ? <LinkNext
        href={href}
        as={as}
        className={`${className} ${displayType || 'block'}`}
        onClick={enableLoader ? () => { setIsLoading(true); onClick(); } : onClick}
        {...props}
        ref={ref}
        data-automation={dataAutomation}>

        {children}

    </LinkNext> : <Line />);
})


LinkAnchor.propTypes = {
    className: PropTypes.string,
    displayType: PropTypes.string,
    /**
     * Children must be contain
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]).isRequired,
    href: PropTypes.string,
};

export default LinkAnchor;
